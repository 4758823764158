import request from '@/utils/request'

// 查询订单-退款订单列表
export function listOrderRefund(query) {
  return request({
    url: '/order/order/refund/list',
    method: 'get',
    params: query
  })
}

// 查询订单-退款订单详细
export function getOrderRefund(id) {
  return request({
    url: '/order/order/refund/' + id,
    method: 'get'
  })
}

// 新增订单-退款订单
export function addOrderRefund(data) {
  return request({
    url: '/order/order/refund',
    method: 'post',
    data: data
  })
}

// 修改订单-退款订单
export function updateOrderRefund(data) {
  return request({
    url: '/order/order/refund',
    method: 'put',
    data: data
  })
}

// 删除订单-退款订单
export function delOrderRefund(id) {
  return request({
    url: '/order/order/refund/' + id,
    method: 'delete'
  })
}
