<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
<!--      <a-form-model-item label="订单类型 21.打本订单(order_order_script)" prop="type" >
      </a-form-model-item>
      <a-form-model-item label="原订单id user_user.id" prop="oriOrderId" >
        <a-input v-model="form.oriOrderId" placeholder="请输入原订单id user_user.id" />
      </a-form-model-item>
      <a-form-model-item label="用户id user_user.id" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用户id user_user.id" />
      </a-form-model-item>-->
      <a-form-model-item label="用户名称" prop="nickName" >
        <a-input disabled v-model="form.nickName" placeholder="请输入用户名称" />
      </a-form-model-item>
<!--      <a-form-model-item label="店铺id user_user.id" prop="shopId" >
        <a-input v-model="form.shopId" placeholder="请输入店铺id user_user.id" />
      </a-form-model-item>-->
      <a-form-model-item label="店铺名称" prop="shopName" >
        <a-input disabled v-model="form.shopName" placeholder="请输入店铺名称" />
      </a-form-model-item>
      <a-form-model-item label="退款金额" prop="refundAmount" >
        <a-input disabled v-model="form.refundAmount" placeholder="请输入退款金额" />
      </a-form-model-item>
      <a-form-model-item label="退款状态" prop="refundStatus">
        <a-select style="width: 100%" v-model="form.refundStatus" placeholder="请输入退款状态">
          <a-select-option   v-for="(item, index) in this.customDict.orderRefundtatusEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <!--      <a-form-model-item label="逻辑删除标记，1：删除，0：未删除，默认0" prop="isDeleted" v-if="formType === 1">
              <a-input v-model="form.isDeleted" placeholder="请输入逻辑删除标记，1：删除，0：未删除，默认0" />
            </a-form-model-item>-->
      <a-form-model-item label="退款原因" prop="remark" >
        <a-input disabled v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
<!--      <a-form-model-item label="乐观锁" prop="version" v-if="formType === 1">
        <a-input v-model="form.version" placeholder="请输入乐观锁" />
      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getOrderRefund, addOrderRefund, updateOrderRefund } from '@/api/order/orderRefund'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        type: null,

        oriOrderId: null,

        userId: null,

        nickName: null,

        shopId: null,

        shopName: null,

        refundAmount: null,

        refundStatus: 0,

        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null,

        isDeleted: null,

        remark: null,

        version: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        oriOrderId: [
          { required: true, message: '原订单id user_user.id不能为空', trigger: 'blur' }
        ],

        userId: [
          { required: true, message: '用户id user_user.id不能为空', trigger: 'blur' }
        ],

        nickName: [
          { required: true, message: '用户名称不能为空', trigger: 'blur' }
        ],

        shopId: [
          { required: true, message: '店铺id user_user.id不能为空', trigger: 'blur' }
        ],

        shopName: [
          { required: true, message: '店铺名称不能为空', trigger: 'blur' }
        ],

        refundAmount: [
          { required: true, message: '退款金额不能为空', trigger: 'blur' }
        ],

        refundStatus: [
          { required: true, message: '退款状态  4100:已创建,4110:申请中,4120:待审核,4130:退款受理,4140:已完成不能为空', trigger: 'blur' }
        ],

        isDeleted: [
          { required: true, message: '逻辑删除标记，1：删除，0：未删除，默认0不能为空', trigger: 'blur' }
        ],

        version: [
          { required: true, message: '乐观锁不能为空', trigger: 'blur' }
        ]

      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: { ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,

        type: null,

        oriOrderId: null,

        userId: null,

        nickName: null,

        shopId: null,

        shopName: null,

        refundAmount: null,

        refundStatus: 0,

        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null,

        isDeleted: null,

        remark: null,

        version: null

      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getOrderRefund(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateOrderRefund(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addOrderRefund(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
